<template>
  <v-dialog
    v-model="modalDialog"
    max-width="500px"
    :persistent="true"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Faculty</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="title"
                label="Title"
                name="Title"
                v-validate="'required'"
              ></v-text-field>
              <span style="color: red;" v-show="errors.has('Title')" v-if="valid">{{ errors.first('Title') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="letter"
                label="Faculty letter"
                name="Letter"
                v-validate="'required'"
              ></v-text-field>
              <span style="color: red;" v-show="errors.has('Letter')" v-if="valid">{{ errors.first('Letter') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="abbreviation"
                label="Abbreviation"
                name="Abbreviation"
                v-validate="'required'"
              ></v-text-field>
              <span style="color: red;" v-show="errors.has('Abbreviation')" v-if="valid">{{ errors.first('Abbreviation') }}</span>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="validateForm"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'NewFaculty',
  props: {
    modalDialog: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      title: '',
      abbreviation: '',
      valid: true,
      letter: '',
    }
  },
  methods: {
    close () {
      this.title = ''
      this.abbreviation = ''
      this.letter = ''
      this.valid = false
      this.$emit('offModal', false)
    },
    async validateForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.save()
        } else {
          // form have errors
        }
      })
    },
    async save () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      if (this.title === '' || this.abbreviation === '') {
        this.validate()
      } else {
        const data = {
          title: this.title,
          abbreviation: this.abbreviation,
          letter: this.letter,
          id: this.id,
        }
        try {
          const res = await this.axios({
            url: 'manipulate_faculty',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.$emit('getResponse', res.data)
            this.title = ''
            this.abbreviation = ''
            this.letter = ''
            this.valid = false
            this.close()
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            this.$router.push({ name: 'login-page' })
          } else if (e.response && e.response.status === 400) {
            alert(e.response.data.messages)
            this.title = ''
            this.abbreviation = ''
            this.letter = ''
            this.valid = false
          } else {
            console.log(e)
          }
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
