<template>
  <div style="width: 100%;">
    <v-app>
      <v-snackbar
        :timeout="3000"
        :value="true"
        absolute
        top
        color="success"
        right
        text
        v-if="appear"
      >
        Faculty created
      </v-snackbar>
      <NewFaculty :modalDialog="showup" @offModal="cancel($event)" @getResponse="responseData($event)"></NewFaculty>
      <v-data-table
        :headers="headers"
        :items="facults"
        :loading="loading"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:item.id="{item}">
          <v-chip small :color="`#9c9c9c`" style="color: white; font-weight: 600; width: 25px; padding: 8px;">
            {{facults.map(function(x) {return x.id; }).indexOf(item.id)+1}}
          </v-chip>
        </template>
        <template v-slot:item.title="{ item }">
          <input type="text" style="width: 100%;" :value="item.title" :id="'title'+item.id" @dblclick="editMode(item.id,'title')" @blur="blurMode(item.id,'title')" @keyup.enter="blurMode(item.id,'title')"  readonly/>
        </template>
        <template v-slot:item.abbreviation="{ item }">
          <input type="text" :value="item.letter" :id="'letter'+item.id" @dblclick="editMode(item.id,'letter')" @blur="blurMode(item.id,'letter')" @keyup.enter="blurMode(item.id,'letter')"  readonly/>
        </template>
        <template v-slot:item.abbreviation="{ item }">
          <input type="text" :value="item.abbreviation" :id="'abbreviation'+item.id" @dblclick="editMode(item.id,'abbreviation')" @blur="blurMode(item.id,'abbreviation')" @keyup.enter="blurMode(item.id,'abbreviation')"  readonly/>
        </template>
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Faculty</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="callModal"
              style="text-transform: none;"
            >
              New faculty
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mt-2"
            @click="edit(item),state='edit'"
          >
          </v-icon>
        </template>
      </v-data-table>
    </v-app>
  </div>
</template>
<script>
import {
  mdiAccount,
  mdiPencil,
  mdiShareVariant,
  mdiDelete,
} from '@mdi/js'
import NewFaculty from './NewFaculty'
export default {
  components: {
    NewFaculty,
  },
  data: () => ({
    icons: {
      mdiAccount,
      mdiPencil,
      mdiShareVariant,
      mdiDelete,
    },
    headers: [
      { text: '#', value: 'id' },
      { text: 'Title', value: 'title' },
      { text: 'Letter', value: 'letter' },
      { text: 'Abbreviation', value: 'abbreviation' },
      { text: '', value: 'actions' },
    ],
    date: new Date().toISOString().substr(0, 7),
    modal: false,
    dialogModal: false,
    logger: [],
    header: '',
    editedItem: {
      abbreviation: '',
      title: '',
    },
    facults: [],
    showup: false,
    loading: false,
    appear: false,
  }),
  created () {
    this.fetchFacults()
  },
  methods: {
    editMode: function (facultyId, key) {
      var faculty = document.getElementById(key + '' + facultyId)
      alert(faculty)
      faculty.focus()
      faculty.readOnly = false
      faculty.classList.add('editMode')
    },
    blurMode (facultyId, key) {
      var faculty = document.getElementById(key + '' + facultyId)
      faculty.readOnly = true
      faculty.classList.remove('editMode')
      this.editFaculty(facultyId, key, faculty.value)
    },
    async editFaculty (faculty, key, value) {
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        faculty: faculty,
        key: key,
        value: value,
      }
      try {
        await this.axios({
          url: 'manipulate_faculty/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else {
          console.log(e)
        }
      }
    },
    async fetchFacults () {
      this.loading = true
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_faculty',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.facults = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } if (error.response && error.response.status === 400) {
          this.loading = false
        } else {
          console.log(error)
        }
      }
    },
    callModal () {
      this.showup = true
    },
    cancel (event) {
      this.showup = event
    },
    responseData (event) {
      this.appear = true
      this.facults.push(event)
    },
  },
}
</script>
<style scoped>
  .editMode {
    padding: 3px;
    background-color: #fdf9ff;
    font-size: 14px;
    border: 1px solid;
    border-radius: 3px;
  }
</style>
